import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Subscription } from 'rxjs';
import { ChildrenOutletContexts } from '@angular/router';
import { slideInAnimation } from './shared/animations';

import { RouterStateService } from './services/router-state.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ slideInAnimation ]
})
export class AppComponent implements OnInit{
  title = 'printa-app';
  
  $changePage: Subscription;

  constructor(private router: Router, private contexts: ChildrenOutletContexts, private routingservice: RouterStateService) { 
    this.$changePage = this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
        this.routingservice.prevPage= this.routingservice.currentPage; 
        const url = this.router.url;        
        this.routingservice.currentPage = url.slice(1);
      }
    })
  }

  ngOnInit(): void {
  } 

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

}
