import {
    trigger, animateChild, group,
    transition, animate, style, query
  } from '@angular/animations';

  // Routable animations
  export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* => HomePage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        })
      ], {optional: true}),
      query(':enter', [
        style({ left: '-100%',
        opacity: 0, })
      ], {optional: true}),
      query(':leave', animateChild(), {optional: true}),
      group([
        query(':leave', [
          animate('500ms ease-out', style({ left: '100%',
          opacity: 0 }))
        ], {optional: true}),
        query(':enter', [
          animate('500ms ease-out', style({ left: '0%',
          opacity: 1 }))
        ], {optional: true}),
      ]),
    ]),
    transition('HomePage => AboutPage', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '100%',
        opacity: 0})
      ]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('500ms ease-out', style({ left: '-100%',
          opacity: 0}))
        ]),
        query(':enter', [
          animate('500ms ease-out', style({ left: '0%',
          opacity: 1}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        })
      ], {optional: true}),
      query(':enter', [
        style({ left: '100%',
        opacity: 0, })
      ], {optional: true}),
      query(':leave', animateChild(), {optional: true}),
      group([
        query(':leave', [
          animate('500ms ease-out', style({ left: '-100%'})),
          animate('0.5s ease-out', style({ opacity: 0 })),
        ], {optional: true}),
        query(':enter', [
          animate('500ms ease-out', style({ left: '0%' })),
          animate('0.5s  ease-out', style({ opacity: 1 })),
        ], {optional: true}),
        query(':enter', animateChild(), {optional: true})
      ]),
    ])    
  ]);