  <header>
  <div class="top-block" style="height: 10vh; width: 100%; position: relative;"></div>
  <nav>
    <ul class="menu">
      <li routerLinkActive="active" ariaCurrentWhenActive="page"><a routerLink="/home">Главная</a></li>
      <li routerLinkActive="active" ariaCurrentWhenActive="page"><a routerLink="/about">О компании</a></li>
      <li routerLinkActive="active" ariaCurrentWhenActive="page"><a routerLink="/prices">Цены</a></li>
      <li routerLinkActive="active" ariaCurrentWhenActive="page"><a routerLink="/conacts">Контакты</a></li>
      <li routerLinkActive="active" ariaCurrentWhenActive="page"><a routerLink="/credentials">Реквизиты</a></li>
      <li routerLinkActive="active" ariaCurrentWhenActive="page"><a href="/vacancies" ariaCurrentWhenActive="page">Вакансии</a></li>
      <li routerLinkActive="active" ariaCurrentWhenActive="page"><a a routerLink="/store">Распродажа</a></li>
    </ul>
  </nav>
</header>
<div [@routeAnimations]="getRouteAnimationData()">
  <router-outlet></router-outlet>
</div>




