import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutCompanyComponent } from './components/about-company/about-company.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CredentialsComponent } from './components/credentials/credentials.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { VacanciesComponent } from './components/vacancies/vacancies.component';
import { PricesComponent } from './components/prices/prices.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutCompanyComponent,
    HomePageComponent,
    ContactsComponent,
    CredentialsComponent,
    NotFoundComponent,
    VacanciesComponent,
    PricesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
