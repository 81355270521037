import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterStateService {
  prevPage = '';
  currentPage = 'home';
  
}
