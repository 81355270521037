import { Component, OnInit } from '@angular/core';

import { RouterStateService } from '../../services/router-state.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotFoundComponent implements OnInit {
prevRoute: string = '';

  constructor(private routingservice: RouterStateService) {
    
  }

  ngOnInit(): void {
    this.prevRoute = this.routingservice.prevPage === '404' ? '/home': '/' + this.routingservice.prevPage;
  }  

}
