<div class="contacts">    
    <h2>Наш адрес:</h2>
    <p>212030, Республика Беларусь,</p>
    <p>г. Могилев, ул. Крыленко, 12А</p>
    <p>(второй этаж, правое крыло)</p>
    <p>Телефон: <a href="tel:+375222707370">+375(222)70-73-70</a>, </p>
    <p>факс: <a href="tel:+375222749333">+375(222)74-93-33</a></p>
    <p>Эл. почта: <a href="mailto:kim.mogilev@gmail.com" target="self">kim.mogilev@gmail.com </a></p>
    <h3>Схема проезда</h3>
    <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A7ebe30cf815e6968798979fad396dd7dc49ac646f49f7a3bead8565294f019c1&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
</div>
