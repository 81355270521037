<div class="home-page">
    <div class="greeting">       
        <img src="assets/images/printa-balls.png" alt="printa img">
        <p>Мы предлагаем комплексное сервисное обслуживание оргтехники в городе Могилёве и области. Мы профессионально работаем сразу в нескольких направлениях:</p>
    </div>
        <ul class="servise-directions">            
            <li class="">Заправка, восстановление, ремонт картриджей к печатающим устройствам.</li>
            <li class="">Ремонт, текущее обслуживание оргтехники от малой офисной техники до профессиональных высокоскоых и широкоформатных аппаратов, инженерных машин  с наличием на складе широкого ассортимента запчастей.</li>
            <li class="">Поставка тонеров, картриджей и других расходных материалов.</li>
            <li class="">Поставка оргтехники с последующим гарантийным и послегарантийным обслуживанием: от персональных принтеров до инженерных систем.</li>
    </ul>
    
</div>
