<div class="credentials-container">
    <h2>Банковские реквизиты</h2>
    <p>ООО "ОргтехникаСервис"</p>
    <p>УНП 791211967</p>
    <p>юридический адрес: 212030, г. Могилев, ул. Крыленко, 12а</p>
    <p>р/с: BY74PJCB30123003001000000933</p>
    <p>в ЦБУ 300 "Приорбанк" ОАО г. Могилев, ул. Первомайская, 61, </p>
    <p>БИК (BIC SWIFT) PJCBBY2X</p>
    <a href="http://qrcoder.ru" target="_blank"><img src="http://qrcoder.ru/code/?https%3A%2F%2Fegr.gov.by%2Fegrn%2FQR.jsp%3FN%3D791211967&6&0" width="222" height="222" border="0" title="Сведения ЕГР"></a>
</div>
