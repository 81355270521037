import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AboutCompanyComponent } from './components/about-company/about-company.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { CredentialsComponent } from './components/credentials/credentials.component';
import { NotFoundComponent } from './components/notfound/notfound.component';
import { PricesComponent } from './components/prices/prices.component';
import { VacanciesComponent } from './components/vacancies/vacancies.component';


const routes: Routes = [
  {path: 'home', title: 'Главная страница', component: HomePageComponent, data: { animation: 'HomePage' }},
  {path: 'about', title: 'О Компании', component: AboutCompanyComponent, data: { animation: 'AboutPage'}},
  {path: 'conacts', title: 'Контакты', component: ContactsComponent, data: { animation: '*'}},
  {path: 'credentials', title: 'Реквизиты компании', component: CredentialsComponent, data: { animation: '1'}},
  {path: 'prices', title: 'Цены', component: PricesComponent, data: { animation: '3'}},
  {path: 'vacancies', title: 'Вакансии', component: VacanciesComponent, data: { animation: '1'}},
  {path: '404', title: 'Страница не найдена', component: NotFoundComponent, data: { animation: '2'}},  
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'store', loadChildren: () => import('./store/store.module').then(m => m.StoreModule), data: { animation: '3'} },
  { path: '**', redirectTo: '404', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
